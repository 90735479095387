import React, { useEffect, useState } from "react";
import HeaderPayment from "../components/header_payment";
import FooterPayment from "../components/footer_payment";
import { getPaymentTermsData } from "../components/utility";
import { useQuery } from "urql";
import { GetConfigQuery } from "../queries";
import { Link } from "react-router-dom";
function PaymentTerms() {
  const [paymentTermsContent, setpaymentTermsContent] = useState({});
  const [configResults, reexecuteConfigQuery] = useQuery({
    query: GetConfigQuery,
    pause: true,
  });

  const {
    data: configsData,
    fetching: configsFetching,
    error: configsError,
  } = configResults;

  useEffect(() => {
    reexecuteConfigQuery({ requestPolicy: "network-only" });
  }, []);

  useEffect(() => {
    const data = getPaymentTermsData();
    setpaymentTermsContent(data);
  }, []);

  return (
    <div>
      <HeaderPayment />
      <div className="bg-gradient-to-b from-blue-950 to-blue-900 md:pt-8 text-4xl md:text-5xl text-white font-bold py-8">
        {paymentTermsContent.title}
      </div>
      <div className="bg-white">
        <div className="text-left pt-16 pb-8 w-10/12 md:w-8/12 mx-auto bg-white">
          <p className="pb-4 uppercase font-semibold">
            {paymentTermsContent.acceptance}
          </p>
          <div className="w-full text-sm">
            <p>
              <span className="font-semibold">
             You agree to pay the Fee of ${configsData?.config_by_pk?.price || 45} to purchase TravelCare Concierge Membership (valid for 60 days from purchase). You may cancel TravelCare Concierge Membership and receive a full refund to your original form of payment within 24 hours of purchase by notifying us via Email- support@lostandfoundconcierge.com. Under no circumstances will you be entitled to a refund unless you cancel this Membership within 24 hours via Email support@lostandfoundconcierge.com.
              </span>{" "}
            </p>
            <p>&nbsp;</p>
            <p><span className="font-semibold">TravelCare Concierge Memebrship entitles you to our assistance in Filing Unlimited Lost Item Reports.</span> You hereby provide Lost and Found Concierge with the authority to
              contact lost and found departments and custodians on your behalf,
              including the filing of reports. Furthermore, THERE ARE NO
              GUARANTEES OF RECLAIMING LOST ITEMS. You understand that we
              provide services on your behalf filing a lost item claim and
              interacting with such lost and found departments and custodians to
              assist you to retrieve your lost item. Our
              fees do not include shipping and shipping fees must be paid
              separately.</p>
            <p>&nbsp;</p>
            {paymentTermsContent.paragraphs &&
              paymentTermsContent.paragraphs.map((paragraph, index) => {
                const sentences = paragraph.text.split(". "); // Assuming sentences end with a period.

                return (
                  <p className="pb-4">
                    <Link key={index} to={paragraph.link}>
                      <span className="font-semibold">{sentences[0]}. </span>
                      {sentences.slice(1).join(". ")}
                    </Link>
                  </p>
                );
              })}
          </div>
        </div>
      </div>
      <FooterPayment />
    </div>
  );
}

export default PaymentTerms;
